@custom-media --width-l1 (min-width: 22.5em);
@custom-media --width-l2 (min-width: 35em);
@custom-media --width-l3 (min-width: 50em);
@custom-media --width-l4 (min-width: 65em);

:root {
	color-scheme: light dark;
	/* Colors */
	--blue: #009BCD;
	--turquoise: #53C2C9;
	--blue-secondary: #007EA6;
	--darkgrey: #444444;
	--text-color: var(--darkgrey);
	--secondary-text-color: #5C6F75;
	--secondary-large-text-color: #63787E;
	--bg-color: #ECEEF0;
	--divider-color: #D9DBDE;
	--link-underline-default: rgb(0 155 205 / 40%);
	--link-underline-hover: var(--blue);
	--link-underline-active: var(--blue-secondary);
	--action-background-l1: rgb(147 187 201 / 20%);
	--action-background-l2: rgb(147 187 201 / 40%);
	--action-background-l3: rgb(147 187 201 / 50%);

	/* Typefaces */
	--font-fam: 'IBM Plex Sans', system-ui, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen-Sans', 'Ubuntu', 'Cantarell', 'Helvetica Neue', sans-serif;

	/* Spacing */
	--space-l1: 0.625rem;
	--space-l2: 1.25rem;
	--space-l3: 2.5rem;
	--space-l4: 5rem;

}

@media (color-gamut: p3) {
:root {
	--blue: color(display-p3 0.2 0.6 0.85);
	--turquoise: color(display-p3 0.3 0.75 0.75);
	--blue-secondary: color(display-p3 0.15 0.5 0.7);
}
}

@media (prefers-color-scheme: dark) {
:root {
	--bg-color: #202020;
	--text-color: #ECEEF0;
	--secondary-text-color: #C6CCD2;
	--secondary-large-text-color: #B5BDC4;
	--divider-color: #4A545E;
	--action-background-l1: rgb(74 84 94 / 40%);
	--action-background-l2: rgb(74 84 94 / 60%);
	--action-background-l3: rgb(74 84 94 / 70%);
}
}
